import { BackgroundContainerFeature } from 'features/Modules/BackgroundContainer';
import { RichTextFeature } from 'features/Modules/RichText';
import React from 'react';

export const ArticleTextFeature: React.FC<Content.ArticleText> = ({ content, settings, noBackground = false }) => {
	const { noTopMargin } = settings?.properties ?? {};

	return (
		<BackgroundContainerFeature enableBackground={!noBackground} noTopMargin={noTopMargin}>
			<RichTextFeature content={content} />
		</BackgroundContainerFeature>
	);
};
